import React from "react";
import { useRoutes } from "react-router-dom";
import App from "../App";

const MainRouter = () => {
  let routes = useRoutes([
    { path: "/", element: <App /> },
    // ...
  ]);
  return routes;
};

export { MainRouter };
