import styled from "styled-components/macro";

const AppContainerStyled = styled.div`
  background-image: url(${(props: any) => props["data-background"]});
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export { AppContainerStyled };
