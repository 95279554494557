import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./main.scss";
import app_styles from "./App.module.scss";
import ReactGA from "react-ga4";
import { AppContainerStyled } from "./App.styled";

function App() {
  const socialsLinks = {
    instagram: "https://www.instagram.com/goodgracestudios_/",
    youtube: "https://www.youtube.com/@goodgracestudios",
    spotify: "",
    whatsapp: "https://wa.link/o97tn7",
    linkedin: "https://www.linkedin.com/company/goodgracestudios",
    gumroad: "https://goodgracestudios.gumroad.com",
  };

  useEffect(() => {
    ReactGA.initialize("G-RH4VZY0RK7");
  }, []);

  return (
    <AppContainerStyled
      data-background={`${process.env.REACT_APP_CLOUDFRONT_URL}/images/ggs_wallpaper.png`}
    >
      <Helmet>
        <title>Good Grace Studios</title>

        <script>
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1355211648667226');
fbq('track', 'PageView');`}
        </script>

        <noscript>
          {`<img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=1355211648667226&ev=PageView&noscript=1"
          />`}
        </noscript>
        <meta
          name="facebook-domain-verification"
          content="z8ltmdu0jq62ob32l8mcsk0rhgyc5y"
        />
      </Helmet>
      <main>
        <div className={`${app_styles.title_container} horizontal_fx`}>
          <div className={`${app_styles.header_title} is_flex vertical_fx`}>
            <span className={`${app_styles.landing_title}`}>
              Good Grace Studios
            </span>
            <span className={`${app_styles.landing_subtitle} jc_center`}>
              + Impact with Love 🫶🏼
            </span>
          </div>
          <div className={`${app_styles.socials_section} is_flex vertical_fx`}>
            <a href={`${socialsLinks.instagram}`} target="_blank">
              <img
                className={`${app_styles.social_icon}`}
                width="30px"
                height="30px"
                src={`${process.env.REACT_APP_CLOUDFRONT_URL}/images/socials/insta.png`}
                alt="instagram_icon"
              />
            </a>
            {/* <a href={`${socialsLinks.spotify}`}>
              <img
                className={`${app_styles.social_icon}`}
                width="30px"
                height="30px"
                src={`${process.env.REACT_APP_CLOUDFRONT_URL}/images/socials/spotify.png`}
              />
            </a> */}
            <a href={`${socialsLinks.youtube}`} target="_blank">
              <img
                className={`${app_styles.social_icon}`}
                width="30px"
                height="30px"
                src={`${process.env.REACT_APP_CLOUDFRONT_URL}/images/socials/youtube.png`}
                alt="youtube_icon"
              />
            </a>
            <a href={`${socialsLinks.linkedin}`} target="_blank">
              <img
                className={`${app_styles.social_icon}`}
                width="30px"
                height="30px"
                src={`${process.env.REACT_APP_CLOUDFRONT_URL}/images/socials/linkedin.png`}
                alt="linkedin_icon"
              />
            </a>

            <a href={`${socialsLinks.whatsapp}`} target="_blank">
              <img
                className={`${app_styles.social_icon}`}
                width="30px"
                height="30px"
                src={`${process.env.REACT_APP_CLOUDFRONT_URL}/images/socials/wa.png`}
                alt="whatsapp_icon"
              />
            </a>
          </div>
        </div>
      </main>
      <footer className={`${app_styles.footer_box}`}>
        <div className={`${app_styles.footer_option}`}>
          <a href={`${socialsLinks.whatsapp}`}>
            <span> Creemos una canción</span>
          </a>
        </div>
        <div className={`${app_styles.footer_option}`}>
          <span className={`${app_styles.footer_center_gg}`}>GG</span>
        </div>

        <div className={`${app_styles.footer_option}`}>
          <a href={`${socialsLinks.gumroad}`}>
            <span>Merch</span>
          </a>
        </div>
      </footer>
    </AppContainerStyled>
  );
}

export default App;
